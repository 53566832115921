import gql from "graphql-tag";
import { SaleStatsFields } from "graphql-client/fragments/pg-sale-stats";
import { MemberSavingsFields } from "graphql-client/fragments/pg-member-savings";
import { Instock_qtyFields } from "graphql-client/fragments/pg-stock-info";

export const SaleMemberInfoFields = gql`
  fragment SaleMemberInfoFields on SaleMemberInfoResponse {
    __typename
    pgMemberSavings {
      ...MemberSavingsFields
    }
    pgSaleStats {
      ...SaleStatsFields
    }
    stockInfo {
      ...Instock_qtyFields
    }
    headerConfig {
      title
      template
      seoPageTitle
      seoDesc_s
      promoDisplay {
        promoDisplay {
          banners {
            type
            value
          }
          dynamicText {
            attribute
            value
          }
          MediaText {
            MEDIA
          }
        }
      }
    }
  }
  ${MemberSavingsFields}
  ${SaleStatsFields}
  ${Instock_qtyFields}
`;
