import gql from "graphql-tag";
import { CategoryProductFields } from "graphql-client/fragments/category-product-fields";

export const Layer = gql`
  fragment Layer on PromoDisplayLayer {
    __typename
    layerType
    label
    leftCoordinate
    topCoordinate
    flyoutDirection
    dynamicText {
      verticalAlign
      horizontalAlign
      classes
      promoId
      styles
      topCoordinate
      rightCoordinate
      bottomCoordinate
      leftCoordinate
      width
      modalType
    }
    htmlText
    shortText
    link
    modal
    path
  }
`;

export const DynamicText = gql`
  fragment DynamicText on PromoDisplayDynamicText {
    __typename
    layout
    alt
    width
    style
    height
    coords
    productIds
    categoryId
    wistia
    transitionStartStyles
    transitionEndStyles
    transitionInDelay
    exclusionParam
    modalType
    addbanner
    autoplay
    slidetime
    easing
    pauseOnHover
    effecttime
    resetOffScreen
  }
`;

export const CatalogAsset = gql`
  fragment CatalogAsset on PromoDisplayCatalogAsset {
    __typename
    skuId
    categoryId
    productId
    leftCoordinate
    topCoordinate
    flyoutDirection
    quickLook
    id
  }
`;

export const SubItemFields = gql`
  fragment SubItemFields on PromoDisplay {
    __typename
    id
    name
    displayType
    marketingPriority
    products {
      productGallery {
        ...CategoryProductFields
      }
    }
    template
    text
    title
    imageUrl
    linkType
    linkUrl
    targetUrl
    products {
      productGallery {
        ...CategoryProductFields
      }
    }
    dynamicText {
      ...DynamicText
    }
    catalogAssets {
      ...CatalogAsset
    }
    layers {
      ...Layer
    }
  }
  ${Layer}
  ${DynamicText}
  ${CatalogAsset}
`;

export const SubItem = gql`
  fragment SubItem on PromoDisplay {
    __typename
    ...SubItemFields
    subItems {
      ...SubItemFields
    }
  }
  ${SubItemFields}
`;

export const PromoDisplayFields = gql`
  fragment PromoDisplayFields on PromoDisplay {
    __typename
    id
    name
    displayType
    marketingPriority
    subItems {
      ...SubItem
    }
    template
    text
    imageUrl
    linkType
    linkUrl
    targetUrl
    dynamicText {
      ...DynamicText
    }
    catalogAssets {
      ...CatalogAsset
    }
    layers {
      ...Layer
    }
    title
    products {
      productGallery {
        ...CategoryProductFields
      }
    }
  }
  ${Layer}
  ${DynamicText}
  ${CatalogAsset}
  ${SubItem}
  ${CategoryProductFields}
`;
