import gql from "graphql-tag";

export const ZeroResults = gql`
  fragment ZeroResultFields on SearchZeroResults {
    __typename
    searchTerms
    otherBrandSearchResults {
      brandResultCount
      brandName
      brandLink {
        navigationState
        siteState {
          companyCode
          properties {
            brandName
          }
        }
        host
      }
    }
  }
`;

export const AdjustedSearch = gql`
  fragment AdjustedSearchFields on AdjustedSearch {
    __typename
    autoPhrased
    adjustedTerms
    spellCorrected
    originalTerms
  }
`;

export const InstructionResultList = gql`
  fragment InstructionResultList on InstructionResultList {
    __typename
    firstRecNum
    lastRecNum
    recsPerPage
    totalNumRecs
    name
    records {
      productDisplayName
      link
      label
    }
  }
`;

export const OtherInfo = gql`
  fragment OtherInfo on SearchOtherInfo {
    __typename
    results {
      displayName
      link {
        navigationState
        siteState {
          properties {
            brandName
          }
          companyCode
        }
        host
      }
      resultCount
      selected
    }
  }
`;

export const ResultTab = gql`
  fragment ResultTab on SearchResultTab {
    __typename
    companyCode
    resultCount
    displayName
    link {
      navigationState
      siteState {
        properties {
          brandName
        }
        companyCode
      }
      host
    }
    selected
  }
`;

export const RefinementLink = gql`
  fragment RefinementLink on SearchRefinementLink {
    ... on SearchRefinementPlainLink {
      __typename
      navigationState
      label
      localeLabel
      count
      multiSelect
      siteState {
        companyCode
      }
      isSelected
    }
    ... on SearchRefinementImageLink {
      __typename
      navigationState
      siteState {
        companyCode
      }
      label
      count
      multiSelect
      isSelected
    }
  }
`;

export const RefinementMenu = gql`
  fragment RefinementMenu on SearchRefinementMenu {
    __typename
    displayName
    name
    ancestors {
      navigationState
      siteState {
        companyCode
      }
      host
      label
    }
    dimensionName
    numRefinementsToShowInitially
    multiSelect
    refinements {
      ...RefinementLink
    }
    key
    childRefinements {
      __typename
      displayName
      name
      ancestors {
        navigationState
        siteState {
          companyCode
          __typename
        }
        host
        label
        __typename
      }
      dimensionName
      numRefinementsToShowInitially
      multiSelect
      refinements {
        ...RefinementLink
        __typename
      }
      key
    }
  }
  ${RefinementLink}
`;

export const ProductCarousel = gql`
  fragment ProductCarousel on SearchProductCarousel {
    __typename
    maxRecordsBasedOnPrevSearch
    records {
      recordType
      product {
        altImageUrl
        imageUrl
        displayName
        repositoryId
        colorizable
        removeFromBrowse
        priceInfo {
          currencySymbol
          isCustomProduct
          isRetail
          isUnavailable
          priceFilter
          isSale
          priceMessage
          priceRange {
            allOnSale
            assetId
            currencyApplied
            highestFullSkuId
            highestPaidFullSkuId
            lowestFullSkuId
            lowestPaidFullSkuId
            noPriceRange
            onClearance
            onSale
            onSaleAndOnClearance
            previewMode
            salePriceListId
            tradeBest
          }
          rangeType
          showMemberPrice
          strikePriceLabel
          listPrices
          listPriceLabel
          salePrices
          salePriceLabel
          memberPrices
          memberPriceLabel
        }
        swatchInfo {
          swatchesToDisplay {
            imageUrl
            swatchId
          }
          numAdditionalSwatchesAvailable
          numAdditionalSaleSwatchesAvailable
        }
      }
      sku {
        fullSkuId
      }
    }
    title
    name
  }
`;

export const ATGCollectionGalleryInfo = gql`
  fragment ATGCollectionGalleryInfo on CollectionCategory {
    __typename
    id
    displayName
    forceSaleFlag
  }
`;
